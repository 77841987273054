import React, {createContext, useContext, useEffect, useState} from 'react';
import {AuthContextType, AuthProviderProps, IAPIResponse, IAPIUserData, IPing, IUserData,} from '../interfaces/d'
import {API_URLS} from "../config/defaultConfig";
import ApiClient from "./apiClient";

const initialAuthState: AuthContextType = {
    userData: null,
    signIn: async () => {
    },
    signOut: async () => {
    },
    isLoggedIn: false,
    loading: true,
};

const AuthContext = createContext<AuthContextType>(initialAuthState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [userData, setUserData] = useState<IUserData | null>(null);
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const storedUser = localStorage.getItem('user_data');
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                if (parsedUser.session && parsedUser.session.expire_at) {
                    setUserData(parsedUser);
                } else {
                    console.warn("Invalid session data in storage, clearing user data.");
                    localStorage.removeItem('user_data');
                    setUserData(null);
                }
            } catch (e) {
                console.error("Error parsing user data:", e);
                localStorage.removeItem('user_data');
            }
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (userData === null) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }, [userData]);

    const signIn = (userData: IUserData) => {
        if (!userData.session || !userData.session.expire_at) {
            console.warn('Invalid session data');
            return;
        }

        const currentTime = Date.now();
        const expireTime = userData.session.expire_at;

        if (expireTime <= currentTime) {
            console.warn('Session already expired at login.');
            return;
        }

        setUserData(userData);
        localStorage.setItem('user_data', JSON.stringify(userData));
    };

    const signOut = async (isAPICall: boolean = true) => {
        try {
            if (isAPICall) await APISignOut();
        } catch (error) {
            // console.error(error);
        } finally {
            localStorage.removeItem('user_data');
            setUserData(null);
            setIsLoggedIn(false);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                userData,
                signIn,
                signOut,
                loading,
                isLoggedIn: !!(userData && userData.session && Date.now() < userData.session.expire_at),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const APISupremumSignInUsingGoogleCredential = async (credential: object): Promise<IUserData> => {
    const response = await ApiClient.post(API_URLS.GoogleSignIn, credential);
    const data: IAPIResponse<Omit<IAPIUserData, 'session'>> = response.data;

    if (response.status !== 200) {
        throw new Error('Google Sign-In failed');
    }

    // default session expire - 1hour
    const expireAt = Date.now() + 3600 * 1000;

    return {
        ...data.data,
        session: {
            id: data.data.session_id,
            expire_at: expireAt,
        },
    };
};

export const APISignOut = async () => {
    const response = await ApiClient.get(API_URLS.SignOut);
    if (response.status !== 200) {
        throw new Error('Sign-Out failed');
    }

    return response.data;
};

export const CheckSession = async (auth: AuthContextType) => {
    // console.log('Checking session');
    if (!auth.userData || !auth.userData.session) {
        console.warn("No valid session data available for check. Logging out.");
        await auth.signOut(false);
        return false;
    }

    try {
        const response = await ApiClient.get(API_URLS.Ping);
        const data: IAPIResponse<IPing> = response.data;

        if (data.data.is_expired) {
            console.warn('Session expired. Logging out.');
            await auth.signOut(false);
            return false;
        } else {
            auth.userData.session.expire_at = data.data.expire_at;
            return true;
        }
    } catch (error) {
        console.error('Error during session check:', error);
        await auth.signOut(false);
        return false;
    }
};


