import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {APISupremumSignInUsingGoogleCredential, useAuth} from '../services/authService';
import header_styles from './PageHeader.module.scss';
import ai_flow_styles from './PageHeaderAIFlow.module.scss';
import SymbolImage from '../asset/logo_symbol.svg';
import routes from "../routes";
import {GoogleLogin} from "@react-oauth/google";

const menu_items = [
    {name: 'AI Flow', url: routes.AIFlow},
    {name: 'Download', url: routes.download},
    {name: 'Pricing', url: routes.pricing},
    {name: 'API Docs', url: routes.apiDocs},
    {name: 'About', url: routes.about}
];

const PageHeader = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const is_ai_flow_page = location.pathname.startsWith(routes.AIFlow);

    const styles = is_ai_flow_page ? ai_flow_styles : header_styles;

    const [show_desktop_message, set_show_desktop_message] = useState(false);
    const [menu_open, set_menu_open] = useState(false);

    useEffect(() => {
        const user_agent = navigator.userAgent.toLowerCase();
        const mobile_regex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

        if (mobile_regex.test(user_agent)) {
            set_show_desktop_message(true);
        }

        const timer = setTimeout(() => {
            set_show_desktop_message(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const toggle_menu = () => {
        set_menu_open(!menu_open);
    };

    const close_menu = () => {
        set_menu_open(false);
    };

    const HandleSignOut = async () => {
        await auth.signOut(true);
    }

    return (
        <header className={styles.header}>
            {show_desktop_message && (
                <div className={styles.desktop_message}>
                    Currently optimized for desktop view.
                </div>
            )}

            {is_ai_flow_page && (
                <div className={styles.persistent_message}>
                    The desktop application will be released soon, and only editing is available on the web.
                </div>
            )}

            <div className={styles.wrapper}>
                <Link to='/' className={styles.no_underline} onClick={close_menu}>
                    <div className={styles.logo}>
                        <div className={styles.logo_symbol}>
                            <img src={SymbolImage} alt="symbol" style={{height: "30px", verticalAlign: "middle"}}/>
                        </div>
                    </div>
                </Link>

                <button
                    className={`${styles.hamburger_button} ${menu_open ? styles.open : ''}`}
                    onClick={toggle_menu}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div className={`${styles.mobile_menu} ${menu_open ? styles.menu_open : ''}`}>
                    <ul className={styles.menu_list}>
                        {menu_items.map((item, index) => (
                            <li key={index} className={styles.menu_item}>
                                <Link className={styles.no_style_link} to={item.url}
                                      onClick={close_menu}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>

                    <div className={styles.mobile_login_section}>
                        {auth && auth.userData ? (
                            <div onClick={() => {
                                auth.signOut(true);
                                close_menu();
                            }} className={styles.login_signout}>
                                Sign Out
                            </div>
                        ) : (
                            <GoogleLogin
                                shape="rectangular"
                                text="signin_with"
                                theme="filled_black"
                                onSuccess={async (credentialResponse) => {
                                    if (credentialResponse) {
                                        try {
                                            await APISupremumSignInUsingGoogleCredential(credentialResponse).then(response => {
                                                const user_data = response;
                                                auth.signIn({
                                                    ...user_data,
                                                    session: {
                                                        id: user_data.session.id,
                                                        expire_at: user_data.session.expire_at
                                                    },
                                                });
                                                const is_aiflow_path = location.pathname.startsWith('/ai-flow');
                                                const redirect_url = is_aiflow_path ? `${location.pathname}${location.search}` : routes.home;
                                                navigate(redirect_url);
                                                close_menu();
                                            });
                                        } catch (error) {
                                            console.error('Login failed', error);
                                        }
                                    } else {
                                        console.error('Login failed');
                                    }
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.menu}>
                    <ul className={styles.menu_list}>
                        {menu_items.map((item, index) => (
                            <li key={index} className={styles.menu_item}>
                                <Link className={styles.no_style_link} to={item.url}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.login}>
                    {auth && auth.isLoggedIn ? (
                        <div onClick={HandleSignOut} className={styles.login_signout}>
                            Sign Out
                        </div>
                    ) : (
                        <GoogleLogin
                            shape="rectangular"
                            text="signin_with"
                            theme="filled_black"
                            onSuccess={async (credentialResponse) => {
                                if (credentialResponse) {
                                    try {
                                        await APISupremumSignInUsingGoogleCredential(credentialResponse).then(response => {
                                            const user_data = response;
                                            auth.signIn({
                                                ...user_data,
                                                session: {
                                                    id: user_data.session.id,
                                                    expire_at: user_data.session.expire_at
                                                },
                                            });
                                            const is_aiflow_path = location.pathname.startsWith('/ai-flow');
                                            const redirect_url = is_aiflow_path ? `${location.pathname}${location.search}` : routes.home;
                                            navigate(redirect_url);
                                        });
                                    } catch (error) {
                                        console.error('Login failed', error);
                                    }
                                } else {
                                    console.error('Login failed');
                                }
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    )}
                </div>
            </div>
        </header>
    );
}

export default PageHeader;
