import React, {useEffect, useState} from 'react';
import styles from './PricingPage.module.scss';
import {Link, useNavigate} from "react-router-dom";
import routes from "../routes";
import {CheckoutSubscription, GetProductList} from "../services/paymentService";
import {stripePromise} from "../config/defaultConfig";
import {useAuth} from "../services/authService";

const PricingPage: React.FC = () => {
    const [pricingPlans, setPricingPlans] = useState<any[]>([]);
    const [quantities, setQuantities] = useState<{ [priceId: string]: number }>({});
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPricingPlans = async () => {
            try {
                const products = await GetProductList();
                const freePlan = {
                    id: 'free',
                    name: 'Free',
                    description: 'Enjoy basic features at no cost.',
                    features: ['Basic feature 1', 'Basic feature 2'],
                    prices: [],
                };
                const enterprisePlan = {
                    id: 'enterprise',
                    name: 'Enterprise',
                    description: 'Advanced solutions for your business.',
                    features: ['Advanced feature A', 'Advanced feature B'],
                    prices: [],
                };
                setPricingPlans([freePlan, ...products, enterprisePlan]);
            } catch (error) {
                console.error('Error fetching product list:', error);
            }
        };
        fetchPricingPlans();
    }, []);

    const handleGetStarted = () => {
        if (!auth.userData) {
            navigate(`/signin?redirect=${encodeURIComponent(routes.pricing)}`);
            return;
        }
    }

    const handleCheckout = async (priceId: string, productId: string, quantity: number) => {
        if (!auth.isLoggedIn) {
            navigate(`/signin?redirect=${encodeURIComponent(routes.pricing)}`);
            return;
        }

        try {
            const callback = `${window.location.origin}/pricing`;
            const cancel_url = `${window.location.origin}/pricing`;
            const session = await CheckoutSubscription(priceId, productId, quantity, callback, cancel_url);
            if (!session.id) {
                throw new Error('Invalid session ID');
            }

            const stripe = await stripePromise;
            if (!stripe) {
                throw new Error('Stripe initialization failed');
            }

            await stripe.redirectToCheckout({sessionId: session.id});
        } catch (error) {
            alert('Unable to complete the checkout process. Please try again later.');
        }
    };

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.pricing}>
                    <div className={styles.pricing_wrapper}>
                        <h1 className={styles.pricing_title}>Pricing Plans</h1>
                        <p className={styles.pricing_desc}>Choose the plan that best suits you.</p>
                        <div className={styles.pricing_cards}>
                            {pricingPlans.map((plan) => (
                                <div className={styles.pricing_card} key={plan.id}>
                                    <h2 className={styles.pricing_card_title}>{plan.name}</h2>
                                    {plan.prices && plan.prices.length > 0 ? (
                                        <p className={styles.pricing_card_price}>
                                            ${plan.prices[0].unit_amount / 100}
                                        </p>
                                    ) : (
                                        plan.id === 'free' ? (
                                            <p className={styles.pricing_card_price}>Free</p>
                                        ) : (
                                            <p className={styles.pricing_card_price}>Contact Us</p>
                                        )
                                    )}
                                    <p>{plan.description}</p>
                                    <ul className={styles.pricing_card_list}>
                                        {plan.features.map((feature: string, index: number) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                    {auth.userData?.subscription.product_name === plan.id ? (
                                        <span className={styles.current_plan_button}>Current Plan</span>
                                    ) : (
                                        plan.id === 'free' ? (
                                            <button
                                                className={styles.pricing_card_button}
                                                onClick={handleGetStarted}
                                            >
                                                Get Started
                                            </button>
                                        ) : plan.prices && plan.prices.length > 0 ? (
                                            <div className={styles.quantity_container}>
                                                <input
                                                    type="number"
                                                    min="1"
                                                    value={quantities[plan.prices[0].id] || 1}
                                                    onChange={(e) => {
                                                        const value = parseInt(e.target.value, 10);
                                                        setQuantities({
                                                            ...quantities,
                                                            [plan.prices[0].id]: value,
                                                        });
                                                    }}
                                                    className={styles.quantity_input}
                                                />
                                                <button
                                                    className={styles.pricing_card_button}
                                                    onClick={() =>
                                                        handleCheckout(
                                                            plan.prices[0].id,
                                                            plan.id,
                                                            quantities[plan.prices[0].id] || 1
                                                        )
                                                    }
                                                >
                                                    Buy Now
                                                </button>
                                            </div>
                                        ) : (
                                            <Link className={styles.pricing_card_button} to={routes.contactUs}>Contact
                                                Us</Link>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PricingPage;
