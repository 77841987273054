import axios, {AxiosInstance} from 'axios';
import {APP_BASE_URL, ErrorCodes} from "../config/defaultConfig";
import {useAuth} from "./authService";
import routes from "../routes";

axios.defaults.withCredentials = true;

const ApiClient: AxiosInstance = axios.create({
    baseURL: APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

ApiClient.interceptors.request.use(config => {
    const storedData = localStorage.getItem('user_data');
    const userData = storedData ? JSON.parse(storedData) : null;

    if (userData?.session.id) {
        config.headers['session_id'] = userData.session.id;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

ApiClient.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const auth = useAuth();

        if (error.response) {
            const {code, msg} = error.response.data.error;

            switch (code) {
                case ErrorCodes.SESSION_EXPIRED:
                    console.warn(`Error: ${msg}`);
                    await auth.signOut(false);
                    window.location.href = routes.signIn;
                    break;

                case ErrorCodes.UNAUTHORIZED:
                    await auth.signOut(false);
                    console.warn(`Unauthorized: ${msg}`);
                    break;

                case ErrorCodes.VALIDATION_ERROR:
                    await auth.signOut(false);
                    console.error(`Validation error: ${msg}`);
                    break;

                default:
                    await auth.signOut(false);
                    console.error(`Unknown error [${code}]: ${msg}`);
                    break;
            }
        } else {
            await auth.signOut(false);
            console.error('Unknown error');
        }

        return Promise.reject(error);
    }
);

if (process.env.NODE_ENV === 'development') {
    import('../mocks/setupMock').then(setupMock => {
        setupMock.default(ApiClient);
        console.log('## Axios Mock Adapter Setup');
    });
}

export default ApiClient;
