import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import styles from './SignInPage.module.css';
import ImageSymbol from '../asset/singin_symbol.svg';
import {APISupremumSignInUsingGoogleCredential, useAuth} from "../services/authService";
import {GoogleLogin} from '@react-oauth/google';
import routes from "../routes";

const SignInPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const redirectTo = searchParams.get('redirect') || routes.home;


    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.signin}>
                    <div className={styles.signin_symbol}>
                        <img src={ImageSymbol} alt='symbol'/>
                    </div>
                    <div className={styles.signin_title}>
                        Sign in to your account
                    </div>
                    <div className={styles.signin_desc}>
                        Use your social accout or email service to continue with CNAPS.AI
                    </div>

                    {/*<div className={styles.signin_button}>*/}
                    {/*    <button onClick={() => login()}>*/}
                    {/*        <img src={ImageLogoGoogle} alt='Google SignIn' /> Sign In with Google*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    <GoogleLogin
                        shape="rectangular"
                        text="signin_with"
                        theme="filled_black"

                        onSuccess={async (credentialResponse) => {
                            if (credentialResponse) {
                                try {
                                    // auth only, non-response data
                                    await APISupremumSignInUsingGoogleCredential(credentialResponse).then(response => {
                                        const user_data = response;
                                        auth.signIn({
                                            ...user_data,
                                            session: {
                                                id: user_data.session.id,
                                                expire_at: user_data.session.expire_at
                                            },
                                        });
                                        navigate(redirectTo);
                                    });
                                } catch (error) {
                                    console.error('Login failed', error);
                                }
                            } else {
                                console.error('Login failed');
                            }
                        }} onError={() => {
                        console.log('Login Failed');
                    }}
                    />

                    {/*<div className={styles.divider_with_text}>OR</div>*/}

                    <div className={styles.email_signin}>
                        {/*<div className={styles.name}>* Your email</div>*/}
                        {/*<input disabled type='email' className={styles.inputbox} />*/}

                        {/*<div className={styles.password_desc}>*/}
                        {/*    <div className={styles.name}>* Password</div>*/}
                        {/*    <div className={styles.show}>SHOW</div>*/}
                        {/*</div>*/}
                        {/*<input disabled type='password' className={styles.inputbox} />*/}

                        {/*<div>*/}
                        {/*    <input disabled type='checkbox' className={styles.checkbox} /> Remember me*/}
                        {/*</div>*/}


                        {/*<div>*/}
                        {/*    <button disabled className={styles.email_signin_button}>Sign in</button>*/}
                        {/*</div>*/}

                        {/*<div className={styles.email_signup_desc}>*/}
                        {/*    Don't have an account? <Link to='/signup'>Sign up</Link>*/}
                        {/*</div>*/}
                    </div>

                </div>
            </div>
        </main>
    )
}

export default SignInPage;
