import React from 'react';
import styles from './LibraryIcon.module.scss';

import {useAuth} from "../../services/authService";
import {Tooltip} from "react-tooltip";

interface IconProps {
    onClick?: () => void;
}

const LibraryIcon: React.FC<IconProps> = ({onClick}) => {
    const auth = useAuth();
    return (
        <>
            <img src="/images/aiflow/icon_library.svg"
                 className={styles.bottom_library_icon} onClick={onClick}
                 data-tooltip-id="libraryTooltip"
                 data-tooltip-content={!auth.userData ? "Please sign in to access this feature" : ""}
                 data-totltip-place="left"
                 alt="Library Icon" width="120" height="120"/>

            <Tooltip id="libraryTooltip" hidden={auth.userData ? true : false}/>
        </>
    );
};

export default LibraryIcon;
