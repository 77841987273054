import React, {useEffect, useState} from 'react';
import styles from './SignInPage.module.css';
import {APISupremumSignInUsingGoogleCredential, CheckSession, useAuth} from "../services/authService";
import {GoogleLogin} from '@react-oauth/google';
import {APPLICATION_EXECUTE_PREFIX} from "../config/defaultConfig";
import dayjs from "dayjs";

const AppAuthPage = () => {
    const auth = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleOpenApp = async () => {
        if (auth.userData) {
            const currentTime = dayjs();
            const expireTime = dayjs(auth.userData.session.expire_at);

            if (expireTime.isAfter(currentTime)) {
                // If the session is valid
                window.open(`${APPLICATION_EXECUTE_PREFIX}session_id=${auth.userData.session.id}`);
                return;
            }
        }

        // Check session and handle logout state
        const result = await CheckSession(auth).catch(() => false);
        if (result) {
            window.open(`${APPLICATION_EXECUTE_PREFIX}session_id=${auth.userData?.session.id}`);
        } else {
            // Keep the logout state, do not use navigate
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        if (auth.userData) {
            // Check session when the app is loaded
            CheckSession(auth)
                .then(result => setIsLoggedIn(result))
                .catch(() => setIsLoggedIn(false));
        } else {
            setIsLoggedIn(false);
        }
    }, [auth.userData]);

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.signin}>
                    <div className={styles.signin_symbol}>
                        <a href='/'><img src='/images/cnapsai_symbol.svg' alt='symbol'/></a>
                    </div>
                    <div className={styles.signin_title}>
                        Sign in to your account
                    </div>
                    <div className={styles.signin_desc}>
                        {!isLoggedIn
                            ? "Use your social account or email service to continue with CNAPS.AI"
                            : "Login successful! Launch the AI Flow Studio below."
                        }
                    </div>
                    {isLoggedIn ? (
                        <button className={styles.openStudioButton} onClick={handleOpenApp}>
                            Open the AI Flow Studio
                        </button>
                    ) : (
                        <GoogleLogin
                            shape="rectangular"
                            text="signin_with"
                            theme="filled_black"
                            onSuccess={async (credentialResponse) => {
                                if (credentialResponse) {
                                    try {
                                        const user_data = await APISupremumSignInUsingGoogleCredential(credentialResponse);
                                        auth.signIn({
                                            session: {
                                                id: user_data.session.id,
                                                expire_at: user_data.session.expire_at
                                            },
                                            user: user_data.user,
                                            subscription: user_data.subscription
                                        });

                                        setIsLoggedIn(true);
                                    } catch (error) {
                                        console.error('Login failed', error);
                                    }
                                } else {
                                    console.error('Login failed');
                                }
                            }}
                            onError={() => {
                                console.error('Login Failed');
                            }}
                            useOneTap
                        />
                    )}
                </div>
            </div>
        </main>
    );
};

export default AppAuthPage;
