import React, {ReactNode} from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from '../services/authService';

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children}) => {
    const auth = useAuth();

    if (!auth || !auth.userData) {
        return <Navigate to="/signin" replace/>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
