import React, {useEffect, useState} from 'react';
import styles from './ProfileEdit.module.scss';
import {ProfileEditModalStage} from "../interfaces/d";
import {saveUserProfile} from "../services/userService";
import {useAuth} from "../services/authService";


const ProfileEdit: React.FC<{ setModalStage: (stage: ProfileEditModalStage) => void }> = ({setModalStage}) => {
    const auth = useAuth();

    if (!auth || !auth.isLoggedIn || !auth.userData) {
        setModalStage(ProfileEditModalStage.OFF);
    }

    const user = auth.userData!;

    // const [userAvatar, setUserAvatar] = useState(user?.profile?.avatar ? user?.profile?.avatar?.original : '/images/profile_avatar.svg');
    // const [userJob, setUserJob] = useState(user?.profile?.job ? user?.profile?.job : '');
    // const [userLocation, setUserLocation] = useState(user?.profile?.location ? user?.profile?.location : '');
    // const [userMessage, setUserMessage] = useState(user?.profile?.message ? user?.profile?.message : '');
    // const [userName, setUserName] = useState(user?.profile?.name ? user?.profile?.name : '');
    // const [isPublicUser, setIsPublicUser] = useState(user?.is_public);
    const [userAvatar, setUserAvatar] = useState('/images/profile_avatar.svg');
    const [userJob, setUserJob] = useState('');
    const [userLocation, setUserLocation] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [isPublicUser, setIsPublicUser] = useState(false);

    const [uploadAvatarFile, setUploadAvatarFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setUploadAvatarFile(file);
        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setPreviewUrl(reader.result as string);
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (userAvatar === '') {
            setUserAvatar('/images/profile_avatar.svg');
        }
    }, [userAvatar]);

    const handleProfileSaveButton = () => {
        saveUserProfile({
            avatar: uploadAvatarFile,
            job: userJob,
            location: userLocation,
            name: userName,
            message: userMessage,
            is_public: isPublicUser
        }).catch(error => {
            console.error(error);
        })
        setModalStage(ProfileEditModalStage.OFF);
    }

    return (
        <>
            <div className={styles.close_button}>
                <img src='/images/upload_box_close_button.svg' alt='close'
                     onClick={() => {
                         setModalStage(ProfileEditModalStage.OFF)
                     }}/>
            </div>
            <div className={styles.main}>
                <div className={styles.title}>Edit Profile</div>

                <div className={styles.profile_wrapper}>
                    <div className={styles.profile_image_container}>
                        {previewUrl ?
                            <img src={previewUrl} alt='avatar' className={styles.avatar}/>
                            :
                            <img src={userAvatar} alt='avatar' className={styles.avatar}/>
                        }
                        <label htmlFor="avatarUpload" className={styles.avatar_upload_icon}>
                            <img src='/images/avatar_upload_icon.svg' alt='upload'/>
                            <input
                                id="avatarUpload"
                                type="file"
                                accept="image/*"
                                style={{display: 'none'}}
                                onChange={handleImageChange}
                            />
                        </label>
                    </div>


                    <div className={styles.profile_message_wrapper}>
                        <div className={styles.profile_message_title}>About Me</div>
                        <textarea className={styles.profile_message} value={userMessage}
                                  onChange={(e) => setUserMessage(e.target.value)}/>
                    </div>
                </div>

                <div
                    style={{borderBottom: "1px solid var(--third-25, #47505A)", width: "100%", margin: "20px 0"}}></div>

                <div className={styles.section_title}>User Name</div>
                <input className={styles.section_edit_box} type='text' value={userName}
                       onChange={(e) => setUserName(e.target.value)}/>

                <div className={styles.section_title}>Job Title</div>
                <input className={styles.section_edit_box} type='text' value={userJob}
                       onChange={(e) => setUserJob(e.target.value)}/>

                <div className={styles.section_title}>Country</div>
                <input className={styles.section_edit_box} type='text' value={userLocation}
                       onChange={(e) => setUserLocation(e.target.value)}/>

                <div className={styles.toggle_switch_wrapper}>
                    <span className={styles.public_profile_label}>Public Profile</span>
                    <label className={styles.toggle_switch}>
                        <input
                            type="checkbox"
                            checked={isPublicUser}
                            onChange={(e) => setIsPublicUser(e.target.checked)}
                        />
                        <span className={styles.slider}></span>
                    </label>
                </div>


                <button className={styles.save_button} onClick={handleProfileSaveButton}>Save</button>
            </div>
        </>
    )
}

export default ProfileEdit;
