import {API_URLS} from "../config/defaultConfig";
import {ErrorCode, IAPIResponse, IProduct} from "../interfaces/d";
import ApiClient from "./apiClient";

export const CheckoutSubscription = async (
    priceId: string,
    productId: string,
    quantity: number,
    callback: string,
    cancel_url: string
) => {
    try {
        const response = await ApiClient.post(API_URLS.CheckoutSubscription, {
            callback,
            cancel_url,
            price_id: priceId,
            product_id: productId,
            quantity,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetProductList = async (): Promise<IProduct[]> => {
    try {
        const response = await ApiClient.get(API_URLS.ProductList);
        const data: IAPIResponse<{ products: IProduct[] }> = response.data;

        if (data.result === ErrorCode.NoError && (!data.error || data.error.code === 0)) {
            const products: IProduct[] = data.data.products;
            return products;
        } else {
            throw new Error(data.error?.msg || 'Unknown error');
        }
    } catch (error) {
        console.error('error get product list:', error);
        throw error;
    }
};

